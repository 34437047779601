@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

$system-default-font-family: -apple-system, BlinkMacSystemFont, 'Lato' ;

$heading-font-family: $system-default-font-family;
$heading-font-weight: 600;

$content-font-family: $system-default-font-family;
$content-font-weight: 400;

%font-heading {
  font-family: $heading-font-family;
  font-weight: $heading-font-weight;
  color: $color-heading-text;
}

%font-content {
  font-family: $content-font-family;
  font-weight: $content-font-weight;
  color: $color-text;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}